<template>
  <div class="home">
    <CartWidget></CartWidget>

    <kinesis-container id="screen-1" class="screen">
      <kinesis-element class="screen__bg screen--1" :strength="-3"></kinesis-element>

      <div class="screen__top"></div>

      <kinesis-element class="screen__logo img-w" :strength="4">
        <img src="@/assets/img/logo.png" alt="">
      </kinesis-element>

      <div class="screen__text screen__text--1" >
        <kinesis-element class="screen__title screen__title--1" :strength="4">
          <b>BLACK RABBIT.</b><br>
          ОТЛИЧНОЕ НАЧАЛО
        </kinesis-element>
        <kinesis-element class="screen__descr screen__descr--1" :strength="4">
          Сложно обойтись без подгузников,
          но можно выбрать те, которые требуют меньше внимания
        </kinesis-element>
        <kinesis-element class="screen__button-box" :strength="4">
          <button v-scroll-to="'#store'" @click="gtmSend('brshop', 'cta', 'click', 'BLACK RABBIT. ОТЛИЧНОЕ НАЧАЛО')" class="button">Заказать</button>
        </kinesis-element>
      </div>
    </kinesis-container>

    <kinesis-container class="screen screen--2">
      <div class="screen__top"></div>

      <kinesis-element class="screen__text screen__text--2" :strength="4">
        <h2 class="screen__title screen__title--2">
          <b>Black Rabbit</b>
          эстетичнее, удобнее <br>
          и безопаснее
        </h2>

        <div class="screen__descr screen__descr--2" v-html="descr2"></div>

        <div class="screen__button-box screen__button-box--2">
          <button v-scroll-to="'#store'"
                  @click="gtmSend('brshop', 'cta', 'click', 'Black Rabbit эстетичнее, удобнее и безопаснее')"
                  class="button">Заказать</button>
        </div>
      </kinesis-element>


    </kinesis-container>

    <kinesis-container class="screen screen--3">
      <div class="screen__top mobile-hidden"></div>

      <kinesis-element class="num" :strength="4">
        <div class="num__num">1</div>
        <div class="num__text">
          <h3 class="num__title num__title--1">
            Ничего лишнего
          </h3>
          <p class="num__descr num__descr--1">
            Произведен без применения отдушек, хлора, парабенов.
            Поэтому он мягче, чем другие и не имеет посторонних запахов
          </p>

          <div class="screen__button-box">
            <button v-scroll-to="'#store'"
                    @click="gtmSend('brshop', 'cta', 'click', 'Ничего лишнего')"
                    class="button">Заказать</button>
          </div>
        </div>
      </kinesis-element>

    </kinesis-container>

    <kinesis-container class="screen screen--4">

      <div class="screen__top screen__top--dh"></div>

      <kinesis-element class="num num--2" :strength="4">
        <div class="num__num num__num--2">2</div>
        <div class="num__text num__text--2">
          <h3 class="num__title num__title--2" v-html="title2"></h3>
          <p class="num__descr num__descr--2">
            Вдвое тоньше, а впитывает больше с технологией
            True Absorb. Создан для эстетики и комфорта,
            практически не виден под одеждой.
          </p>
          <div class="screen__button-box screen__button-box--num2">
            <button v-scroll-to="'#store'"
                    @click="gtmSend('brshop', 'cta', 'click', 'ТОНКО ПРО КРАСОТУ')"
                    class="button">Заказать</button>
          </div>
        </div>
      </kinesis-element>

    </kinesis-container>

    <kinesis-container class="screen screen--5">
      <div class="screen__top"></div>

      <kinesis-element class="num num--3" :strength="4">
        <div class="num__num">3</div>
        <div class="num__text num__text--3">
          <h3 class="num__title num__title--3">
            ПРОДУМАН В ДЕТАЛЯХ
          </h3>
          <p class="num__descr num__descr--3">
            Каждый подгузник – в индивидуальной упаковке,
            надежно защищен от микробов. Проще утилизировать
            – для использованного подгузника уже есть готовый пакет.
            Компактный – удобно носить в сумке.
          </p>
          <div class="screen__button-box">
            <button v-scroll-to="'#store'"
                    @click="gtmSend('brshop', 'cta', 'click', 'ПРОДУМАН В ДЕТАЛЯХ')"
                    class="button">Заказать</button>
          </div>
        </div>
      </kinesis-element>

    </kinesis-container>

    <section id="store" class="store">
      <div class="store__bg-image">
        <div v-if="isDiapersActive" class="store__bg-image--1"></div>
        <div v-else class="store__bg-image--2"></div>
      </div>
      <div class="store__products">
        <Store></Store>
      </div>
    </section>

    <kinesis-container class="screen screen--6">
      <div class="screen__top mobile-hidden"></div>

      <kinesis-element class="num num--wo" :strength="4">
        <div class="num__num num__num--img img-w">
          <img src="@/assets/img/ig-ico.png" alt="">
        </div>
        <div class="num__text num__text--ig">
          <h3 class="num__title num__title--ig">
            <b>ПОДПИШИТЕСЬ <br> НА НАС</b> В INSTAGRAM
          </h3>

          <div class="num__ig-button-box">
            <a href="https://www.instagram.com/blackrabbit.us/" target="_blank">
              <button class="button-transparent">@blackrabbit.us</button>
            </a>
          </div>
        </div>
      </kinesis-element>

      <kinesis-element class="screen__text screen__text--5 mobile-hidden" :strength="4">
        <div v-scroll-to="'#screen-1'" class="screen__button-box screen__bottom-logo img-w">
          <img src="@/assets/img/logo.png" alt="">
        </div>
      </kinesis-element>

      <kinesis-element class="screen__footer" :strength="4">
        BLACK RABBIT, 2021.
        <p class="screen__legal" v-html="legal"></p>
      </kinesis-element>
    </kinesis-container>

  </div>
</template>

<script>
import Store from '@/components/Store'
import CartWidget from '@/components/CartWidget'
import { mapState, mapMutations } from 'vuex'
import { KinesisContainer, KinesisElement} from 'vue-kinesis'
import axios from 'axios'

export default {

  name: 'Home',
  data() {
    return {
      descr2: 'Подгузники без&nbsp;компромиссов',
      title2: 'ТОНКО ПРО&nbsp;КРАСОТУ',
      legal: 'ООО&nbsp;«Большой&nbsp;мир», ИНН&nbsp;9704030343, ОГРН&nbsp;1207700330155,  тел.&nbsp;8(495)&nbsp;150-77-68'
    }
  },

  components: {
    Store, CartWidget, KinesisContainer, KinesisElement
  },

  computed: {
    ...mapState([
      'isDiapersActive', 'isLogoHidden', 'apiUrl'
    ]),

  },

  methods: {
    ...mapMutations([
      'changeLogoVisibility'
    ]),

    gtmSend(event, category, action, label) {
      window.dataLayer.push({
        'event': event,
        'eventCategory': category,
        'eventAction': action,
        'eventLabel': label,
      });
    },

    handleScroll(event) {
      let scroll = event.target.scrollingElement.scrollTop
      const pageHeight = document.documentElement.scrollHeight
      const blockHeight = document.documentElement.clientHeight

      if (scroll >= blockHeight && scroll < (pageHeight - (blockHeight + 15))) {
        this.changeLogoVisibility({param: false})
      } else {
        this.changeLogoVisibility({param: true})
      }
    },

    getLayouts() {
      axios.get(this.apiUrl + '/api/getLayouts')
        .then(function () {

        })
        .catch(function (error) {
          console.log(error);
        })
    }
  },

  mounted() {
    this.getLayouts()
  },

  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>
